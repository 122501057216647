import React from 'react';
import {
  ComponentPreview,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  Section,
} from 'docComponents';
import { FormSection, FormModifier, Input } from 'hudl-uniform-ui-components';
import SectionPlatform from '../partials/_SectionPlatform';
import pageHeroData from '../../../../data/pages/components.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Form Section"
      designCode
      subnav="components">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Forms"
        tierThree="Form Section"
      />

      <PageNavigation>
        <PageNavigationLink>Usage</PageNavigationLink>
        <PageNavigationLink>Platform</PageNavigationLink>
      </PageNavigation>

      <Section title="Usage">
        <Paragraph>
          Using the form section automatically applies the semantic code
          structure to every section of a form, making it easy to organize the
          form into coherent, logical parts.
        </Paragraph>
        <ComponentPreview name="FormSection">
          <FormModifier density="compact">
            <FormSection sectionText="Billing Address">
              <Input type="text" label="Street Address" />
              <Input type="text" label="State" />
              <Input type="text" label="Zip Code" />
            </FormSection>
            <FormSection sectionText="Shipping Address">
              <Input type="text" label="Street Address" />
              <Input type="text" label="State" />
              <Input type="text" label="Zip Code" />
            </FormSection>
          </FormModifier>
        </ComponentPreview>
      </Section>

      <SectionPlatform />
    </PageWithSubNav>
  );
};

export default IndexPage;
